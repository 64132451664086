import styled from "styled-components";

const HeaderStyles = styled.section`
  background-color: var(--navbarBg);
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 2rem;

  .title,
  .subtitle {
    font-family: Helvetica, sans-serif;
    position: relative;
  }
  .title {
    font-size: 2rem;
    animation-duration: 0.75s;
    animation-name: transformTitle;
  }
  .subtitle {
    color: var(--disabled);
    opacity: 0.9;
    animation-duration: 3s;
    animation-name: transformSubtitle;
  }

  @keyframes transformTitle {
    from {
      transform: translate3d(0px, -15px, 0px);
      opacity: 0;
    }
    to {
      transform: translate3d(0px, 0px, 0px);
      opacity: 1;
    }
  }

  @keyframes transformSubtitle {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.9;
    }
  }
`;

export default function Headline({ title, subtitle }) {
  return (
    <HeaderStyles>
      <h1 className="title">{title}</h1>
      <h4 className="subtitle">{subtitle}</h4>
    </HeaderStyles>
  );
}
