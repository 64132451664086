import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useContext } from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import Headline from "../components/Headline";
import LayoutStyles from "../components/layout";
import MainContent from "../components/mainContent";
import Navbar from "../components/Navbar";
import SEO from "../components/SEO";
import ThemeContext from "../contexts/ThemeContext";
import GlobalStyles, { shadow } from "../styles/GlobalStyles";
import moment from 'moment';

const ImageGroupStyles = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  margin: 3rem 0;

  .gallery-image-wrapper {
    width: 30vw;
  }

  .gallery-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;

    ${shadow}
  }

  .gallery-image:nth-child(1) {
    margin: 0 0.5rem;
  }

  @media (max-width: 900px) {
    .gallery-image-wrapper {
      width: 45vw;
    }
    .gallery-image-wrapper:nth-child(even) {
      display: none;
    }
  }

  @media (max-width: 690px) {
    .gallery-image-wrapper {
      width: 100%;
    }
    .gallery-image-wrapper:nth-child(odd) {
      display: none;
    }
    .gallery-image-wrapper:nth-child(even) {
      display: inherit;
    }
  }
`;

const AboutContentStyles = styled.article`
  margin: var(--main-content-margin);

  @media (min-width: 900px) {
    padding: 1rem 20%;
  }
`;

const SectionStyles = styled.section`
  ${shadow}

  padding: 1rem 2rem;
  margin-bottom: 2rem;
  border-radius: var(--borderRadius);
  background-color: var(--primary);

  .section-title {
    color: var(--highlight);
  }
`;

const TechnologiesStyles = styled.section`
  width: 100%;
  display: grid;
  gap: 2rem 2rem;
  grid-template-columns: repeat(auto-fill, minmax(var(--column-width--small), 1fr));

  .technology-group {
    padding: 0 1rem;
  }

  ul {
    padding-inline-start: 16px;
  }
`;

const QualificationStyles = styled.ul`
  list-style: none;
  padding: 0;

  li {
    margin: 1rem 0;
    &::before {
      content: "🎓 ";
    }
  }
`;

const renderImages = (nodes) => {
  if (!nodes) {
    return;
  }

  return (
    <ImageGroupStyles>
      {nodes.map(({ node }) => (
        <div className="gallery-image-wrapper" key={node.id}>
          <GatsbyImage
            image={node.childImageSharp.gatsbyImageData}
            alt={node.name}
            style={{ width: "100%" }}
            className="gallery-image"
            loading="eager"
          />
        </div>
      ))}
    </ImageGroupStyles>
  );
};

const intro = () => (
  <SectionStyles>
    <h3>
      Hey!{" "}
      <span role="img" aria-label="happy emoji">
        😃
      </span>
    </h3>
    <p>I&apos;m Peter, a Senior Software Developer from Glasgow, Scotland 🏴󠁧󠁢󠁳󠁣󠁴󠁿</p>
  </SectionStyles>
);

const work = (yearsAsDeveloper) => (
  <SectionStyles>
    <h4 className="section-title">Experience</h4>
    <p>
      I have been a Software Developer for {yearsAsDeveloper} and am experienced with TypeScript, React, Next.js, and Ruby on Rails.

      I&apos;ve worked in eCommerce for <a href="https://ooni.com" target="_blank" rel="noopener noreferrer">Ooni</a>, the Care Industry for <a href="https://www.caresourcer.com/" target="_blank" rel="noopener noreferrer">CareSourcer</a>, and FinTech for <a href="https://www.experian.co.uk/" target="_blank" rel="noopener noreferrer">Experian</a> and Castlight Financial.
    </p>
    <p>
      I got into the Software industry through{" "}
      <a href="https://codeclan.com/" target="_blank" rel="noopener noreferrer">
        Codeclan
      </a>
      , Scotland&apos;s only award-winning digital skills academy. After Codeclan I
      joined a small agile team tasked with rebuilding the Affordability
      Passport® at Castlight Financial which was later acquired by{" "}
      <a
        href="https://www.experian.co.uk/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Experian
      </a>
      .
    </p>
  </SectionStyles>
);

const technologies = () => {
  const apis = [
    "GraphQL",
    "Postman",
    "REST",
    "Swagger"
  ];
  const CICD = [
    "Azure VSTS",
    "CircleCI",
    "Github Actions",
    "Octopus Deploy"
  ];
  const cloud = [
    "AWS",
    "Azure",
    "Heroku",
    "Netlify",
    "Serverless Functions",
    "Vercel"
  ];
  const databases = [
    "Firestore",
    "MySQL",
    "PlanetScale",
    "Postgres"
  ];
  const languages = [
    "JavaScript",
    "Ruby",
    "TypeScript"
  ];
  const librariesAndFrameworks = [
    "Angular 9",
    "Gatsby",
    "Next.js",
    "Node.js",
    "React",
    "Ruby on Rails"
  ];
  const monitoring = [
    "Azure",
    "New Relic",
    "Sentry"
  ];
  const styling = [
    "Boostrap",
    "CSS3",
    "SASS",
    "SCSS"
  ];
  const testing = [
    "Cypress",
    "Jasmine / Karma",
    "Jest / Enzyme",
    "Jest",
    "Minitest",
    "Pact Contract Testing",
    "Rspec"
  ];
  const thirdParty = [
    "Amplitude",
    "Auth0",
    "Contentful",
    "Google Analytics",
    "Google Tag Manager",
    "Klaviyo",
    "Iterable",
    "Shopify"
  ];
  const tools = [
    "DBeaver",
    "PGAdmin",
    "Postman",
    "Sequel Ace",
    "Swagger"
  ];
  const versionControl = [
    "Azure",
    "Git",
    "Github",
    "Gitlab"
  ];

  const technologyGroups = [
    {
      name: "APIs",
      items: apis
    },
    {
      name: "CI/CD",
      items: CICD
    },
    {
      name: "Cloud",
      items: cloud
    },
    {
      name: "Databases",
      items: databases
    },
    {
      name: "Languages",
      items: languages
    },
    {
      name: "Libraries & Frameworks",
      items: librariesAndFrameworks
    },
    {
      name: "Monitoring",
      items: monitoring
    },
    {
      name: "Styling",
      items: styling
    },
    {
      name: "Testing",
      items: testing
    },
    {
      name: "Third Party",
      items: thirdParty
    },
    {
      name: "Tools",
      items: tools
    },
    {
      name: "Version Control",
      items: versionControl
    }
  ]

  const listItems = (items) => items.map((item, index) => <li key={`${index}-${item}`}>{item}</li>);

  return (
    <SectionStyles>
      <h4 className="section-title">Technologies</h4>
      <p>
        Below is a list of technologies I have worked with during my time in the
        industry.
      </p>
      <p>
        Most recently I have been working with Next.js, Ruby on Rails, GraphQL APIs, REST APIs,
        SCSS, Sentry, Github Actions, Contentful CMS, and Serverless Functions.
      </p>
      <TechnologiesStyles>
        {technologyGroups.map(group => (
          <div className="technology-group" key={group.name}>
            <h4>{group.name}</h4>
            <ul>{listItems(group.items)}</ul>
          </div>
        ))}
      </TechnologiesStyles>
    </SectionStyles>
  );
};

const qualifications = () => (
  <SectionStyles>
    <h4 className="section-title">Qualifications</h4>
    <QualificationStyles>
      <li>
        PDA in Software Development from CodeClan
      </li>
      <li>
        MSc in Geospatial & Mapping Sciences from the University of Glasgow
      </li>
      <li>BSc in Geography from the University of Glasgow</li>
    </QualificationStyles>
  </SectionStyles>
);

const hobbies = () => (
  <SectionStyles>
    <h4 className="section-title">What makes me happy</h4>
    <p>
      Outside of work I enjoy traveling and my interests include playing
      football, snowboarding, running and hill-walking.
    </p>
  </SectionStyles>
);

export default function IndexPage({ data }) {
  const { theme } = useContext(ThemeContext);
  const yearsAsDeveloper = moment('2018-02-05').fromNow(true);

  return (
    <LayoutStyles>
      <GlobalStyles theme={theme} />
      <SEO
        title="About Me | My experience, education and interests"
        titleTemplate={data?.site?.siteMetadata?.title}
        description={data?.site?.siteMetadata?.description}
      />
      <Navbar />
      <Headline title="Software Developer" subtitle="Peter McCready" />
      <MainContent>
        {renderImages(data.allFile.edges)}
        <AboutContentStyles>
          {intro()}
          {work(yearsAsDeveloper)}
          {technologies()}
          {qualifications()}
          {hobbies()}
        </AboutContentStyles>
      </MainContent>
      <Footer />
    </LayoutStyles>
  );
}

export const pageQuery = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allFile(filter: { relativeDirectory: { eq: "about-images" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData(
              layout: FIXED
              height: 350
              width: 350
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;
